import { XCircleIcon } from "@heroicons/react/24/solid";
import { withTranslation } from "react-i18next";

function Errors({ t, errors }) {

    return errors && errors.length ? <div className="rounded-md bg-red-50 p-4 my-2">
        <div className="flex">
            <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-left">
                {errors && typeof (errors) !== 'boolean' ? (typeof errors === "string" ? <div className="mt-2 text-sm text-red-700">{errors}</div> : <><h3 className="text-sm font-medium text-red-800">{t("An error occured")}</h3><div className="mt-2 text-sm text-red-700">
                    <ul className="list-disc space-y-1 pl-5 text-left">
                        {errors.map(err => <li>{err}</li>)}
                    </ul>
                </div></>) : null}

            </div>

        </div>
    </div> : null
}

export default withTranslation()(Errors);