import React, { useContext, useEffect, useState } from "react";


import { withTranslation } from "react-i18next";
import { Button } from "../catalyst-component/button";
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { getPayboxParameters } from "../../utils/requests/paybox";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import * as FormsComponent from "../Inputs/forms/index";
import { PencilIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../Animation/LoadingSpinner";
const countrycodes = require('../../assets/countrycode.json');


const errorList = {
    "00000": " Opération réussie.",
    "00001": "La connexion au centre d’autorisation a échoué ou une erreur interne est survenue.",
    "00002": "Une erreur de cohérence est survenue.",
    "00003": "Erreur Paybox.",
    "00004": "Numéro de porteur ou cryptogramme visuel invalide.",
    "00006": "Accès refusé ou site/rang/identifiant incorrect.",
    "00008": "Date de fin de validité incorrecte.",
    "00010": "Devise inconnue.",
    "00011": "Montant incorrect.",
    "00012": "Référence commande invalide.",
    "00013": "Cette version n’est plus soutenue.",
    "00014": "Trame reçue incohérente.",
    "00015": "Émetteur de carte inconnu.",
    "00016": "Référence de paiement invalide.",
    "00021": "Carte non autorisée.",
    "00022": "Plafond atteint",
    "00029": "Carte non conforme.",
    "99999": "",
    "00097": "Timeout de connexion atteint.",
    "00098": "Erreur de connexion interne.",
    "canceled": "Paiement annulé",

}

const currencyList = [{ "option": "978", "label": "€" }, { "option": "840", "label": "$" }, { "option": "826", "label": "£" }]

function Paybox({ t, question, formId }) {
    const variables = useContext(VariablesContext)
    const msalInstance = useContext(MsalInstanceContext);
    const [errorShow, setErrorShow] = useState(false);
    const [payboxParameters, setPayboxParameters] = useState(false);
    const [formType, setFormType] = useState(false);
    const [lockInfo, setLockInfo] = useState(false);
    const [errors, setErrors] = useState(null);

    const [formBuyer, setFormBuyer] = useState({
        firstName: "",
        lastName: "",
        email: "",
        adress1: "",
        adress2: "",
        zipCode: "",
        city: "",
        country: ""
    })




    useEffect(() => {
        if (window.location.href.endsWith("/paid")) {
            setErrorShow(true);
        }
        var formTypeLoc = window.location.href.split("/")[3];

        setFormType(decodeURI(formTypeLoc));

    }, [])

    function getParameters() {
        setErrors(null);
        if (formBuyer.firstName === "" || formBuyer.lastName === "" || formBuyer.email === "" || formBuyer.adress1 === "" || formBuyer.zipCode === "" || formBuyer.city === "" || formBuyer.country === "") {
            setErrors("Veuillez remplir tous les champs obligatoires")
        } else {
            setLockInfo(true);
            setPayboxParameters(false);
            setErrorShow(false);
            getPayboxParameters(msalInstance, variables, formId, question.id, formBuyer).then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then(err => {
                        throw err;
                    });
                }
            }).then(data => {
                setPayboxParameters(data);
            }).catch(err => {
                setErrors(err);
                setLockInfo(false);
            })
        }
    }



    return question ? <div>
        {question?.value === "paid" || question?.options[0]?.value === "0" ?
            <div className="p-4  mt-6 bg-green-100/70">
                <div className="flex">
                    <div className="shrink-0">
                        <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-md font-medium">{question?.options[0]?.value === "0" ? "Commande gratuite" : "Paiement réussi"}</h3>
                        <div className="mt-2 text-sm ">
                            {question?.options[0]?.value === "0" ? "Votre commande est entièrement gratuite ! Aucun paiement n'est requis." : "Votre paiement a été validé par Paybox"}
                        </div>
                    </div>
                </div>
            </div>
            : (question?.value === "waiting" ?
                <div className="p-4  mt-6 bg-yellow-100/70">
                    <div className="flex">
                        <div className="shrink-0">
                            <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-md font-medium">{t("Waiting for paiement")}</h3>
                            <div className="mt-2 text-sm ">
                                Nous attendons la réponse de Paybox concernant votre paiement. Revenez plus tard.
                            </div>
                        </div>
                    </div>
                </div> : (errorShow ?
                    <div className="p-4  mt-6 bg-red-100/70">
                        <div className="flex">
                            <div className="shrink-0">
                                <XCircleIcon aria-hidden="true" className="size-5 text-red-600" />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-md font-medium">{t("Une erreur est survenue lors de votre paiement")}</h3>
                                <div className="mt-2 text-sm ">
                                    {errorList[question?.value]}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null))}
        {question?.value === "paid" || question?.options[0]?.value === "0" ? <div className="text-center items-center align-middle py-auto place-content-center text-2xl px-24 ">
            {/* Vous allez être redirigé vers la page de confirmation de votre commande dans quelques secondes... */}
        </div> : (question?.value !== "waiting" ? <div className="mt-6">
            {/* Récapitulatif de la commande */}
            <div className="p-4  mt-6 bg-gray-200/70">
                <div className="flex w-full">
                    <div className="shrink-0">
                        <ReceiptPercentIcon aria-hidden="true" className="size-5 text-gray-600" />
                    </div>
                    <div className="ml-3  w-full">
                        <h3 className="text-md font-medium">{t("Récapitulatif de la commande")}</h3>
                        <div className="flex justify-between text-sm mt-2">
                            <div>Frais de <b>{formType}</b></div>
                            <div className="">
                                <span className="text-black text-md font-bold">{question?.options[0]?.value ? (question?.options[0]?.value.slice(question?.options[0]?.value.length - 2) !== "00" ? [question?.options[0]?.value.slice(0, question?.options[0]?.value.length - 2), ",", question?.options[0]?.value.slice(question?.options[0]?.value.length - 2)].join('') : question?.options[0]?.value.slice(0, question?.options[0]?.value.length - 2)) : null}</span>
                                <span className="text-black text-md font-bold">{question?.options[1]?.value ? currencyList.find(cL => cL.option === question?.options[1]?.value).label : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Formulaire de paiement */}
            <div className="mt-6">
                <form className="bg-white shadow-sm ring-1 ring-gray-900/5">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="px-4 sm:px-0 flex justify-between">
                            <div><h2 className="text-base/7 font-semibold text-gray-900">Informations de facturation</h2>
                                {!lockInfo && <p className="mt-1 text-sm/6 text-gray-600">Veuillez renseigner vos informations pour la facturation pour finaliser votre commande.</p>}
                            </div>
                            {lockInfo && <div>
                                <PencilIcon className="h-6 w-6 text-gray-500 cursor-pointer" onClick={() => setLockInfo(false)} />
                            </div>}
                        </div>
                        {!lockInfo ?
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4">
                                {errors ? <div className="col-span-full text-red-500">{errors}</div> : null}
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                        Prénom *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="firstName" label="Prénom" value={formBuyer.firstName} onChange={(e) => setFormBuyer({ ...formBuyer, firstName: e })} />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm/6 font-medium text-gray-900">
                                        Nom *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="lastName" label="Nom" value={formBuyer.lastName} onChange={(e) => setFormBuyer({ ...formBuyer, lastName: e })} />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                        Email *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="email" label="Email" value={formBuyer.email} onChange={(e) => setFormBuyer({ ...formBuyer, email: e })} />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                                        Pays *
                                    </label>
                                    <div className="mt-2 grid grid-cols-1">
                                        <select id="country" label="Pays" className="border block w-full sm:text-xs border-gray-300 rounded-md h-8 px-3 text-xs"
                                            onChange={(e) => {
                                                setFormBuyer({ ...formBuyer, country: e.target.value })
                                            }}
                                            value={formBuyer.country}>
                                            <option value=""></option>
                                            {countrycodes.map(c => { return { label: c.name, value: c["country-code"] } }).map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900">
                                        Adresse *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="adress1" label="Adresse" value={formBuyer.adress1} onChange={(e) => setFormBuyer({ ...formBuyer, adress1: e })} />
                                    </div>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="city" className="block text-sm/6 font-medium text-gray-900">
                                        Ville *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="city" label="Ville" value={formBuyer.city} onChange={(e) => setFormBuyer({ ...formBuyer, city: e })} />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="postal-code" className="block text-sm/6 font-medium text-gray-900">
                                        Code postal *
                                    </label>
                                    <div className="mt-2">
                                        <FormsComponent.Input id="zipCode" label="Code postal" value={formBuyer.zipCode} onChange={(e) => setFormBuyer({ ...formBuyer, zipCode: e })} />
                                    </div>
                                </div>
                            </div>
                            : <div>
                                <div>
                                    {formBuyer.firstName}{" "}{formBuyer.lastName}{" "}( {formBuyer.email} )
                                </div>
                                <div>
                                    {formBuyer.adress1}{" "}{formBuyer.adress2}{" "}{formBuyer.zipCode}{" "}{formBuyer.city}{" "}( {countrycodes.find(c => c["country-code"] === formBuyer.country).name} )
                                </div>
                            </div>}
                    </div>
                    {!lockInfo && <div className="flex items-center justify-end gap-x-6 px-4 py-4 sm:px-8">
                        <Button type="button" className="cursor-pointer"
                            onClick={getParameters}>{t("Enregistrer")}</Button>
                    </div>}
                </form>
                {/* <div className="">
                    <div className="flex justify-between">
                    </div>
                    <FormsComponent.Input id="adress2" label="Complément d'adresse" value={formBuyer.adress2} onChange={(e) => setFormBuyer({ ...formBuyer, adress2: e.target.value })} />
                    <div className="flex justify-between">
                    </div>
                </div> */}
                <div>

                </div>
            </div>
            <div className="flex items-center justify-center my-8">
                {payboxParameters && lockInfo ?
                    <form method="POST" action={payboxParameters.options.find(opt => opt.label === "url")?.value?.split(" ")[0]}>
                        {payboxParameters.options.filter(opt => opt.label !== "url").sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder)).map(opt => <input type="hidden" name={opt.label} value={opt.value} />)}
                        <Button className="cursor-pointer" type="submit">{t("Procéder au paiement")}</Button>
                    </form> : null
                }
                {
                    lockInfo && !payboxParameters ? <LoadingSpinner /> : null
                }
            </div>

        </div> : null)
        }
    </div > : null
}

export default withTranslation()(Paybox);