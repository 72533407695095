import { getAccessToken } from "../../auth/verifyAuth";

async function getPayboxParameters(msalInstance, variables, formId, questionId, formBuyer) {
    var accessToken = await getAccessToken(msalInstance, variables);
    return fetch(window.location.origin + "/api/v1.0/payboxurl", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...formBuyer,
            formId : formId,
            questionId : questionId
        })
    })
}

export {
    getPayboxParameters
}